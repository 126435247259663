const { default: axios } = require('axios')

const instance = axios.create({
  baseURL: 'https://api.datablockai.com/api/v1',
  headers: { 'Content-Type': 'application/json' },
})

export const generateSocialMediaCampaignPosts = args => instance.post('/test/social-media', args)

export const regenerateSocialMediaCampaignPostImage = args => instance.post('/social/monster', args)
