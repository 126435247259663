<template>
  <div>
    <b-overlay
      :show="isProcessing"
    >

      <full-calendar
        ref="campaignCalendar"
        :options="config"
      >
        <template v-slot:eventContent="arg">
          <post-event-title
            class="w-100"
            :post="arg.event"
          />
        </template>
      </full-calendar>
    </b-overlay>
    <event-details-modal @refetch-calendar="campaignUid => getCompanyCampaigns(campaignUid)" />

    <edit-post-modal @refetch-calendar="postUid => { getCompanyCampaigns(null, postUid) }" />
  </div>
</template>

<script>
import {
  BOverlay,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import moment from 'moment'
import useApollo from '@/plugins/graphql/useApollo'
import { mapActions, mapGetters } from 'vuex'
import EventBus from '@/event-bus'
import {
  checkProgress, getCurrentCampaignProgresses, setCurrentCampaign, setCurrentCampaignProgresses, setCurrentPost,
} from './useSocialCampaign'
import EditPostModal from './components/EditPostModal.vue'
import EventDetailsModal from './components/EventDetailsModal.vue'
import PostEventTitle from './components/PostEventTitle.vue'

export default {
  components: {
    BOverlay,
    FullCalendar,
    EditPostModal,
    EventDetailsModal,
    PostEventTitle,
  },
  data() {
    return {
      campaigns: [],
      calendarApi: null,
      isProcessing: false,
      checkProgressInterval: null,
    }
  },
  computed: {
    ...mapGetters('socialCampaign', {
      campaignEvents: 'getCampaignEvents',
      campaignPosts: 'getCampaignPosts',
    }),
    config() {
      return {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: 'dayGridMonth',
        // eventOrderStrict: true,
        headerToolbar: {
          start: 'prev,next,create',
          center: 'title',
          end: '',
        },
        eventOrder: ['createdAt'],
        customButtons: {
          create: {
            text: 'Create Social Campaign',
            click: () => { this.$bvModal.show('event-details-modal') },
          },
        },
        displayEventTime: false,
        events: [...this.campaignEvents, ...this.campaignPosts],
        // dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
      }
    },
  },
  mounted() {
    this.calendarApi = this.$refs.campaignCalendar.getApi()
    this.getCompanyCampaigns(null, true)

    EventBus.$on('check-progress', campaignUid => {
      this.checkProgressInterval = setInterval(async () => {
        const res = await checkProgress(campaignUid)

        if (res?.status === 'completed') {
          setCurrentCampaignProgresses({ uuid: campaignUid, batch: null }, 'remove')
          this.getCompanyCampaigns()
          this.isProcessing = false
          clearInterval(this.checkProgressInterval)
          this.$bvModal.hide('event-details-modal')
        }
      }, 15000)
    })
  },
  methods: {
    ...mapActions('socialCampaign', ['initalizeCampaignEvents', 'updateCampaignEvent', 'updateCampaignPosts', 'clearPrevCampaignPosts']),
    // handleDateClick(selectInfo) {
    //   const calendarApi = selectInfo.view.calendar
    //   calendarApi.addEvent({ title: 'Hello', start: new Date() })
    // },
    getCompanyCampaigns(currentCampaign = null, currentPost = null) {
      this.isProcessing = true
      const params = {
        companyUid: [this.$store.state.project.selectedCompany],
      }
      useApollo.company.getCompanyCampaigns(params).then(response => {
        const res = response.data.campaigns.data
        if (res.length) {
          const campaigns = res.map(({ generations, ...campaign }) => ({
            ...campaign,
            generations: {
              data: generations.data.map(post => ({
                ...post,
                campaignUid: campaign.uuid,
              })),
              total: generations.total,
            },
            title: campaign.name,
            start: moment(campaign.createdAt).toDate(),
            batch: campaign.batches.data[0].id,
          }))

          this.initalizeCampaignEvents(campaigns)
          this.clearPrevCampaignPosts()
          campaigns.forEach(campaign => {
            if (campaign.generations.data.length) {
              const posts = campaign.generations.data.map(post => ({
                ...post,
                date: moment(post.scheduledAt).toDate(),
                color: moment(post.scheduledAt).isSameOrAfter(moment(), 'date') ? 'green' : 'red',
              }))
              const postData = {
                campaignUid: campaign.uuid,
                posts,
              }
              this.updateCampaignPosts(postData)
            }

            if (!currentCampaign && campaign.batches?.data?.length) {
              const pendingBatches = campaign.batches.data.filter(batch => batch.child?.pendingJobs)
              if (pendingBatches.length) {
                this.checkCampaignGenerationProcess(campaign.uuid, pendingBatches[0].id)
              }
            }
          })

          if (currentCampaign) {
            this.selectedCampaign = campaigns.find(campaign => campaign.uuid === currentCampaign)
          }

          if (currentPost) {
            setCurrentPost(this.campaignPosts.find(post => post.uuid === currentPost))
          }
        }
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    checkCampaignGenerationProcess(campaignUid, batchUid) {
      setCurrentCampaignProgresses({ uuid: campaignUid, batch: batchUid }, 'add')

      const checkBatch = async () => {
        const { status } = await checkProgress(campaignUid)
        if (status === 'completed') {
          clearInterval(this.checkProgressInterval)
          setCurrentCampaignProgresses({ uuid: campaignUid, batch: batchUid }, 'remove')
          this.getCompanyCampaigns()
        }
      }

      // Inital check
      checkBatch()

      // Set up batch check interval
      this.checkProgressInterval = setInterval(checkBatch, 15000)
    },
    handleEventClick(eventInfo) {
      const { extendedProps } = eventInfo.event._def
      const selectedCampaign = this.campaignEvents.find(campaign => campaign.uuid === extendedProps.uuid)
      setCurrentCampaign(selectedCampaign)
      if (selectedCampaign) {
        this.$nextTick(() => {
          this.$bvModal.show('event-details-modal')
        })
      } else {
        const selectedPost = this.campaignPosts.find(post => post.uuid === extendedProps.uuid)
        setCurrentPost(selectedPost)

        if (selectedPost) {
          this.$nextTick(() => {
            this.$bvModal.show('sc-edit-post-modal')
          })
        }
      }
    },
  },
}
</script>
