import useApollo from '@/plugins/graphql/useApollo'
import moment from 'moment'
import { ref } from 'vue'

const currentCampaign = ref(null)
const currentPost = ref(null)
const currentCampaignProgresses = ref([])

export const getCurrentCampaign = () => currentCampaign.value
export const getCurrentPost = () => currentPost.value

export const getCurrentCampaignProgresses = campaignUid => (campaignUid ? currentCampaignProgresses.value.find(el => el.uuid === campaignUid) : currentCampaignProgresses.value)

export const setCurrentCampaign = campaign => { currentCampaign.value = campaign }
export const setCurrentPost = post => { currentPost.value = post }
export const setCurrentCampaignProgresses = ({ uuid, batch }, action) => {
  if (action === 'add') {
    currentCampaignProgresses.value.push({
      uuid,
      batch,
      progress: 0,
      total: 0,
    })
  } else if (action === 'remove') {
    currentCampaignProgresses.value = currentCampaignProgresses.value.filter(el => el.uuid !== uuid)
  }
}
export const getPreviousPost = (currentPostDate, campaign) => {
  setCurrentPost(campaign?.generations?.data.find(post => moment(currentPostDate).subtract(1, 'days').isSame(post.scheduledAt)))
}

export const getNextPost = (currentPostDate, campaign) => {
  setCurrentPost(campaign?.generations?.data.find(post => moment(currentPostDate).add(1, 'days').isSame(post.scheduledAt)))
}

export const checkProgress = async campaignUid => {
  const campaign = currentCampaignProgresses.value.find(el => el.uuid === campaignUid)

  const response = await useApollo.company.getCampaignProgressBatch({ batchUid: campaign?.batch })
  const res = response.data?.batch
  if (res?.child) {
    const { totalJobs, pendingJobs, failedJobs } = res.child
    campaign.progress = totalJobs - pendingJobs + failedJobs
    campaign.total = totalJobs
    if (campaign.progress === campaign.total) return { status: 'completed' }

    return { status: 'processing' }
  }
  return null
}
